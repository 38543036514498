import {
    createRouter,
    createWebHashHistory,
    createWebHistory,
} from '@ionic/vue-router'
import {
    NavigationGuardNext,
    RouteLocationNormalized,
    RouteRecordRaw,
} from 'vue-router'
import { App } from '@/services/application'

const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        redirect: '/partidas',
        component: () => import('../TabsContainer.vue'),
        children: [
            {
                name: 'partidas',
                path: '/partidas',
                component: () => import('../views/PartidasPage.vue'),
                beforeEnter: [checkLogin],
                children: [
                ]
            },
            {
                name: 'partida',
                path: '/partidas/:id',
                component: () => import('../views/PartidaPage.vue'),
                props: route => ({ id: route.params.id }),
                beforeEnter: [checkLogin],
            },
            {
                name: 'partida-chat',
                path: '/partidas/:id/chat/:idusuario',
                component: () => import('../views/ChatPage.vue'),
                props: route => ({ id: route.params.idusuario }),
                beforeEnter: [checkLogin],
            },
            {
                name: 'jugar',
                path: '/jugar',
                component: () => import('../views/JugarPage.vue'),
                beforeEnter: [checkLogin],
                children: [
                ]
            },
            {
                name: 'jugar-individual',
                path: '/jugar/individual',
                component: () => import('../views/IndividualPage.vue'),
                beforeEnter: [checkLogin],
                children: [
                ]
            },
            {
                name: 'individual',
                path: '/individual',
                component: () => import('../views/IndividualPage.vue'),
                beforeEnter: [checkLogin],
                children: [
                ]
            },
            {
                name: 'test',
                path: '/test',
                component: () => import('../views/TestView.vue'),
                // beforeEnter: [checkLogin],
            },
            {
                name: 'chats',
                path: '/chats',
                component: () => import('../views/ChatsPage.vue'),
                beforeEnter: [
                    checkLogin
                ],
            },
            {
                name: 'chat',
                path: '/chats/:id',
                component: () => import('../views/ChatPage.vue'),
                props: route => ({ id: route.params.id }),
                beforeEnter: [checkLogin],
            },
            {
                name: 'ranking',
                path: '/ranking',
                component: () => import('../views/RankingPage.vue'),
                beforeEnter: [checkLogin],
            },
            {
                name: 'perfil',
                path: '/perfil',
                component: () => import('../views/PerfilPage.vue'),
                beforeEnter: [checkLogin],
            },
            {
                name: 'estadisticas',
                path: '/estadisticas',
                component: () => import('../views/EstadisticasPage.vue'),
                beforeEnter: [checkLogin],
            },
            {
                name: 'urgencia',
                path: '/urgencia',
                component: () => import('../views/UrgenciaPage.vue'),
                beforeEnter: [checkLogin],
            }
        ]
    },
    {
        name: 'login',
        path: '/login',
        component: () => import('../views/LoginPage.vue'),
        beforeEnter: [checkLogin],
    },
]

async function checkLogin(
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
    next: NavigationGuardNext,
) {
    await App.auth.init()

    if (to.fullPath !== '/login' && !App.auth.isLogged) {
        App.auth.returnRoute = to.path
        next({ name: 'login' })
    } else if (to.fullPath === '/login' && App.auth.isLogged) {
        next({ name: 'partidas' })
    } else {
        next()
    }
}
const router = createRouter({
    history: createWebHashHistory(process.env.BASE_URL),
    routes,
})

export default router
