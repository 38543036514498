<template>
    <IonHeader v-if="props.showHeader" class="ion-no-border">
        <IonToolbar>
            <IonButtons slot="start">
                <IonButton color="medium" @click="cancel">Cancelar</IonButton>
            </IonButtons>
            <IonTitle>
                Urgencias
            </IonTitle>
        </IonToolbar>
    </IonHeader>
    <IonContent>
        <IonGrid class="urgencia-header">
            <IonRow>
                <IonCol size="12" class="ion-text-center">
                    <h2>¡Bienvenidos al modo Urgencias!</h2>
                    <strong>{{ App.urgencias.textoTiempoPendiente }}</strong>
                </IonCol>
            </IonRow>
        </IonGrid>
        <IonGrid>
            <IonRow>
                <IonCol size="12" class="ion-text-center">
                    <h3>Descripción:</h3>
                </IonCol>
                <IonCol size="12">
                    <ul>
                        <li>Duración: 30 minutos</li>
                        <li>Preguntas: 10</li>
                        <li>Objetivo: Responde correctamente el mayor número de preguntas en el menor tiempo posible.
                        </li>
                        <li>Premio: 100 puntos extra para el ganador.</li>
                    </ul>
                </IonCol>

                <IonCol size="12" class="ion-text-center">
                    <h3>Puntuación:</h3>
                </IonCol>
                <IonCol size="12">
                    <ul>
                        <li>Respuesta correcta: 10 puntos</li>
                        <li>Respuesta incorrecta o no respondida: 0 puntos</li>
                    </ul>
                </IonCol>

                <IonCol size="12" class="ion-text-center">
                    <h3>Desempate:</h3>
                </IonCol>
                <IonCol size="12">
                    <ul>
                        <li>En caso de empate en puntos, el jugador que haya respondido correctamente en el menor tiempo
                            total
                            será el
                            ganador.</li>
                    </ul>
                </IonCol>

            </IonRow>

            <IonRow>
                <IonCol size="12" class="ion-text-center">
                    <h3>¡Prepárate para demostrar tu rapidez y conocimiento!</h3>

                    <strong>Consejos:</strong>
                </IonCol>
                <IonCol size="12">
                    <ul>
                        <li>Mantén la calma y concéntrate.</li>
                        <li>Lee bien cada pregunta antes de responder.</li>
                        <li>¡Buena suerte!</li>
                    </ul>
                </IonCol>
            </IonRow>
        </IonGrid>
    </IonContent>
</template>
<script setup lang="ts">
import { App } from '@/services/application';
import { IonGrid, IonRow, IonCol, IonContent, IonButton, IonToolbar, IonHeader, IonButtons, IonTitle, modalController } from '@ionic/vue';


const props = defineProps({
    showHeader: {
        type: Boolean,
        default: false
    }
})

const cancel = () => modalController.dismiss(null, 'cancel');
</script>

<style lang="scss" scoped>
.urgencia-header {
    position: relative;
    color: White;
    background-color: var(--mirial-color-red);

    /* min-height: 200px; */
    /* padding-top: 50px; */
}

:root {
    --ion-text-color: var(--mirial-color-blue);
}
ion-toolbar {
    --background: var(--mirial-color-red);
}
ion-toolbar ion-button.ion-color-medium{
    --ion-color-base: White !important;
}
</style>