import { Usuario } from "@/models/Usuario";
import { application } from "./application";
import { modalController } from '@ionic/vue';
import { Urgencia } from "@/models/Urgencia";
import { Pregunta } from "@/models/Pregunta";
import UrgenciaInfo from "@/views/urgencias/UrgenciaInfo.vue";

export class urgencias {
    App: application
    public Activa!: Urgencia

    constructor(App: application) {
        this.App = App
    }

    /**
     * Recuperar urgencia activa
     */
    async cargar(): Promise<Urgencia> {
        const apiResult = await this.App.api.get("urgencias/cargar")

        if (!apiResult.object) {
            this.Activa = null as any
        } else {
            this.Activa = Object.assign(new Urgencia(), apiResult.object) as Urgencia
        }

        this.actualizarTimers()
        this.actualizarTiempoPendienteUrgencia()
        this.actualizarTiempoPendientePregunta()
        return this.Activa
    }

    /**
     * Recuperar pregunta activa
     */
    async cargarPreguntaActiva(): Promise<Pregunta | null> {
        const apiResult = await this.App.api.get("urgencias/cargar-pregunta")

        if (!apiResult.object) {
            return null;
        } else {
            return Object.assign(new Pregunta(), apiResult.object) as Pregunta
        }
    }


    private timerTiempoPendiente = null as NodeJS.Timeout | null
    private timerDuranteUrgencia = null as NodeJS.Timeout | null
    private timerDurantePregunta = null as NodeJS.Timeout | null
    private timerCuandoNoHayUrgencia = null as NodeJS.Timeout | null
    actualizarTimers() {
        if (!this.Activa) {
            // Remove timers
            if (this.timerTiempoPendiente) {
                clearInterval(this.timerTiempoPendiente)
                this.timerTiempoPendiente = null
            }
            if (this.timerDuranteUrgencia) {
                clearInterval(this.timerDuranteUrgencia)
                this.timerDuranteUrgencia = null
            }
            if (this.timerDurantePregunta) {
                clearInterval(this.timerDurantePregunta)
                this.timerDurantePregunta = null
            }
            // Activar timer cada 5 minutos
            if (!this.timerCuandoNoHayUrgencia) {
                this.timerCuandoNoHayUrgencia = setInterval(async () => {
                    await this.cargar()
                }, 1000 * 60 * 5)
            }
            this.enTiempoParaPregunta = false
        } else {
            // Limpiar timer de "no hay urgencia"
            if (this.timerCuandoNoHayUrgencia) {
                clearInterval(this.timerCuandoNoHayUrgencia)
                this.timerCuandoNoHayUrgencia = null
            }

            if (!this.Activa.activa) {
                // Si la urgencia aun no está activa
                // Si no hay timer, crear
                if (!this.timerTiempoPendiente) {
                    this.timerTiempoPendiente = setInterval(() => {
                        // this.enTiempoParaPregunta = false
                        this.actualizarTiempoPendienteUrgencia()
                    }, 1000)
                }
            } else {
                // Si la urgencia está activa
                // Si no hay timer, crear
                if (!this.timerDuranteUrgencia) {
                    this.timerDuranteUrgencia = setInterval(async () => {
                        await this.cargar()
                        this.actualizarTiempoPendienteUrgencia()
                        this.actualizarTiempoPendientePregunta()
                        // TODO: què mes...
                    }, 5000)
                }

                // Si indice <> 0
                if (this.Activa.indicePregunta != 0) {
                    // Si no hay timer, crear
                    if (!this.timerDurantePregunta) {
                        this.timerDurantePregunta = setInterval(async () => {
                            this.actualizarTiempoPendientePregunta()
                        }, 1000)
                    }
                }
            }
        }
    }
    public textoTiempoPendiente = ''

    actualizarTiempoPendienteUrgencia() {
        if (!this.Activa) {
            this.textoTiempoPendiente = ''
            return;
        }

        // Restar tiempo desde ahora hasta la próxima urgencia
        const ahora = new Date();
        const proxima = new Date(this.Activa.inicio);

        if (proxima.getTime() <= ahora.getTime()) {
            if (this.Activa.activa) {
                this.textoTiempoPendiente = `¡Urgencia en curso!`;
            } else if (this.Activa.finalizada) {
                this.textoTiempoPendiente = `¡Urgencia finalizada!`;
            }
            else {
                this.textoTiempoPendiente = `La urgencia está a punto de comenzar...`;
            }
            // TODO: actualizar
            return;
        }
        if (this.Activa && this.Activa.activa) {
            this.textoTiempoPendiente = `¡Hay una Urgencia en curso!`;
            return;
        }
        if (this.Activa && !this.Activa.activa) {
            // Faltan X horas, Y minutos y Z segundos
            let diff = proxima.getTime() - ahora.getTime();
            const horas = Math.floor(diff / (1000 * 60 * 60));
            diff -= horas * 1000 * 60 * 60;
            const minutos = Math.floor(diff / (1000 * 60));
            diff -= minutos * 1000 * 60;
            const segundos = Math.floor(diff / 1000);

            if (horas == 0 && minutos == 0 && segundos == 0) {
                this.textoTiempoPendiente = `Próxima urgencia en menos de 1 segundo`;
            } else if (horas == 0 && minutos == 0) {
                this.textoTiempoPendiente = `Próxima urgencia en ${segundos}s`;
            } else if (horas == 0 && minutos >= 10) {
                this.textoTiempoPendiente = `Próxima urgencia en ${minutos}m`;
            } else if (horas == 0) {
                this.textoTiempoPendiente = `Próxima urgencia en ${minutos}m ${segundos}s`;
            } else {
                this.textoTiempoPendiente = `Próxima urgencia en ${horas}h ${minutos}m`;
            }

            // si faltan 10 minutos exactos, mostrar toast
            if (horas == 0 && minutos == 10 && segundos == 0) {
                this.App.showToast(`¡Faltan 10 minutos para la próxima urgencia!`)
            }
            // si falta 1 minuto exacto, mostrar toast
            if (horas == 0 && minutos == 1 && segundos == 0) {
                this.App.showToast(`¡Prepárate, sólo falta 1 minuto para la próxima urgencia!`, 'warning', 5000)
            }
        }
    }

    public textoTiempoPendientePregunta = ''
    private updatingTiempoPendientePregunta = false
    public enTiempoParaPregunta = false
    actualizarTiempoPendientePregunta() {
        if (!this.Activa) {
            this.textoTiempoPendientePregunta = ``;
            return;
        }
        if (!this.Activa.activa) {
            this.textoTiempoPendientePregunta = ``;
            this.enTiempoParaPregunta = false;
            return;
        }
        if (this.updatingTiempoPendientePregunta) return;
        this.updatingTiempoPendientePregunta = true;

        // Restar tiempo desde ahora hasta la próxima urgencia
        const ahora = new Date();
        const horaFinPregunta = new Date(this.Activa.finPregunta);

        if (horaFinPregunta.getTime() <= ahora.getTime()) {
            this.textoTiempoPendientePregunta = `¡Tiempo agotado!`;
            this.enTiempoParaPregunta = false;
        } else {

            // Faltan X horas, Y minutos y Z segundos
            let diff = horaFinPregunta.getTime() - ahora.getTime();
            const horas = Math.floor(diff / (1000 * 60 * 60));
            diff -= horas * 1000 * 60 * 60;
            const minutos = Math.floor(diff / (1000 * 60));
            diff -= minutos * 1000 * 60;

            const segundos = Math.floor(diff / 1000);

            if (horas == 0 && minutos == 0 && segundos == 0) {
                this.textoTiempoPendientePregunta = `Menos de 1 segundo`;
            } else if (horas == 0 && minutos == 0) {
                this.textoTiempoPendientePregunta = `${segundos}s`;
            } else if (horas == 0) {
                this.textoTiempoPendientePregunta = `${minutos}m ${segundos}s`;
            } else {
                this.textoTiempoPendientePregunta = `${horas}h ${minutos}m ${segundos}s`;
            }

            this.enTiempoParaPregunta = true;
        }
        this.updatingTiempoPendientePregunta = false;
    }

    async showInformation() {
        const modal = await modalController.create(
            {
                component: UrgenciaInfo,
                componentProps: {
                    showHeader: true,
                },
            }
        )

        modal.present()

        const { data, role } = await modal.onWillDismiss()

        return null
    }
}