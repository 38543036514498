import { Usuario } from "@/models/Usuario";
import { application } from "./application";
import { modalController } from '@ionic/vue';
import UsuariosBuscar from '@/views/components/UsuariosBuscarView.vue';
import { Badge } from '@capawesome/capacitor-badge';

export class usuarios {
    App: application
    public Me!: Usuario
    public idusuario = 0

    constructor(App: application) {
        this.App = App
    }

    async refresh(): Promise<Usuario | null> {
        if( this.App.auth.isLogged == false ) return null;

        let url = "usuarios/me"
        if( this.App.newSession == true ) {
            url = "usuarios/me?newSession=true"
        }

        const apiResult = await this.App.api.get(url)
        const result = Object.assign(new Usuario(), apiResult.object) as Usuario

        this.App.newSession = false

        this.Me = result
        this.idusuario = result.id

        const pendientes = parseInt(result.mensajes) +
            parseInt(result.invitaciones) +
            parseInt(result.partidas)

        Badge.set({ count: pendientes })

        return result
    }

    async cargar(idusuario: number): Promise<Usuario> {
        const apiResult = await this.App.api.get("usuarios/cargar?id=" + idusuario)
        const result = Object.assign(new Usuario(), apiResult.object) as Usuario

        return result
    }

    async buscar(texto: string): Promise<Usuario[]> {
        const apiResult = await this.App.api.get("usuarios/buscar?texto=" + texto)
        const result = apiResult.object as Usuario[]

        return result
    }

    async showBuscarUsuario(): Promise<Usuario | null> {
        const modal = await modalController.create(
            {
                component: UsuariosBuscar,
                componentProps: {
                    onClick: async (usuario: Usuario) => {
                        modal.canDismiss = true;
                        modal.dismiss(usuario, 'confirm')
                    }
                }
            }
        )

        modal.present()

        const { data, role } = await modal.onWillDismiss()

        if (role == 'confirm') {
            return data
        }

        return null
    }
}