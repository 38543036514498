import { Especialidad } from "./Especialidad";

export class Urgencia{
    /**
     * Id de urgencia
     */
    public id = 0;
    /**
     * Fecha y hora de la urgencia
     */
    public inicio!: string;
    /**
     * Fecha y hora fin de la urgencia
     */
    public fin!: string;
    /**
     * Especialidad de la urgencia
     */
    public especialidad!: Especialidad;
    /**
     * Activa
     */
    public activa = false;
    /**
     * Finalizada
     */
    public finalizada = false;
    /**
     * Indice pregunta
     */
    public indicePregunta = 0;
    /**
     * Total preguntas
     */
    public totalPreguntas = 0;
    /**
     * Fecha y hora fin de la pregunta actual
     */
    public finPregunta!: string;
}